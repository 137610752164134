import classNames from 'classnames/bind';
import React from 'react';
import { Avatar } from '../../components/Avatar';
import { Content } from '../../components/Content';
import { Heading } from '../../components/Heading';
import { SearchInput } from '../../components/SearchInput';
import { instructors } from '../../data';
import styles from './Instructors.module.scss';

const cx = classNames.bind(styles);

const Instructors: React.FC = () => {
  return (
    <Content>
      <Heading>
        Instructors
        <SearchInput />
      </Heading>

      <div className={cx('instructor-list')}>
        <div className="row">
          {instructors.map((instructor) => (
            <div key={instructor.id} className="col-sm-6 col-md-4">
              <div className={cx('instructor-card')}>
                <div className={cx('instructor-card-heading')}>
                  <Avatar type="instructor" imageUrl={instructor.avatarUrl} />
                  <div>
                    {instructor.firstName} {instructor.lastName}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Content>
  );
};

export default Instructors;
