import classNames from 'classnames/bind';
import React from 'react';
import { Content } from '../../components/Content';
import { Heading } from '../../components/Heading';
import styles from './Settings.module.scss';

const cx = classNames.bind(styles);

const Settings: React.FC = () => {
  return (
    <Content>
      <Heading>Settings</Heading>
    </Content>
  );
};

export default Settings;
