import classNames from 'classnames/bind';
import React from 'react';
import styles from './SearchInput.module.scss';

const cx = classNames.bind(styles);

const SearchInput: React.FC = () => {
  return <input type="text" placeholder="Search..." className={cx('search-input')} autoFocus spellCheck={false} />;
};

export default SearchInput;
