import classNames from 'classnames/bind';
import { AnimatePresence, motion, Transition } from 'framer-motion';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { Trail } from 'react-spring/renderprops';
import { aircraft, instructors } from '../../data';
import { Slot } from '../../interfaces/slot';
import { BookingService } from '../../services/booking';
import { AircraftChoice } from '../../types/aircraft-choice';
import { InstructorChoice } from '../../types/instructor-choice';
import { Spinner } from '../Spinner';
import { AircraftAutocomplete } from './AircraftAutocomplete';
import styles from './BookingForm.module.scss';
import { BookingFormFooter } from './BookingFormFooter';
import { ReactComponent as CalendarIcon } from './Calendar.svg';
import { InstructorAutocomplete } from './InstructorAutocomplete';
import DefaultAvatar from './InstructorAutocomplete/DefaultAvatar.png';
import NoSlotsIcon from './NoSlots.png';
import WarningIcon from './Warning.png';

const cx = classNames.bind(styles);

const variants = {
  in: { y: 0, opacity: 1 },
  out: { y: 5, opacity: 0 },
};

const transition: Transition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.5,
};

const BookingForm: React.FC = () => {
  const [instructor, setInstructor] = useState<InstructorChoice>();
  const [aircraftChoice, setAircraft] = useState<AircraftChoice>();
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [duration, setDuration] = useState(120);
  const [resultsReady, setResultsReady] = useState(false);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [slotsPage, setSlotsPage] = useState(0);

  const shouldShowResults =
    (aircraftChoice || (instructor && instructor !== 'none')) && (instructor !== 'none' || aircraftChoice !== 'none');

  useEffect(() => {
    if (shouldShowResults) {
      setResultsReady(false);

      setTimeout(() => {
        setSlotsPage(0);
        setSlots(BookingService.findSlots(instructor, aircraftChoice, startDate, duration));
        setResultsReady(true);
      }, 450);
    }
  }, [shouldShowResults, instructor, aircraftChoice, startDate, duration]);

  let selectedInstructorLabel: React.ReactNode = '';
  if (instructor === 'any') {
    selectedInstructorLabel = 'Any instructor';
  } else if (instructor !== 'none') {
    selectedInstructorLabel = (
      <>
        {instructors.find((i) => i.id === instructor)?.firstName}&nbsp;
        {instructors.find((i) => i.id === instructor)?.lastName}
      </>
    );
  }

  let selectedAircraftLabel: React.ReactNode = '';
  if (aircraftChoice === 'any') {
    selectedAircraftLabel = 'Any aircraft';
  } else if (typeof aircraftChoice === 'number') {
    const a = aircraft.find((a) => a.id === aircraftChoice);

    selectedAircraftLabel = (
      <>
        {a?.make}&nbsp;{a?.model}&nbsp;({a?.registration})
      </>
    );
  } else {
    selectedAircraftLabel = aircraftChoice;
  }

  return (
    <motion.div
      variants={variants}
      transition={transition}
      initial="out"
      animate="in"
      exit="out"
      className={cx('form-wrapper')}
    >
      <div className={cx('form')}>
        <div className={cx('form-fields')}>
          <InstructorAutocomplete instructor={instructor} onSelect={setInstructor} />
          <AircraftAutocomplete aircraft={aircraftChoice} onSelect={setAircraft} />
        </div>

        <AnimatePresence>
          {shouldShowResults && (
            <BookingFormFooter
              startDate={startDate}
              duration={duration}
              startDateChanged={setStartDate}
              durationChanged={setDuration}
            />
          )}
        </AnimatePresence>
      </div>

      {instructor === 'none' && aircraftChoice === 'none' && (
        <div className={cx('error-message')}>
          <img src={WarningIcon} alt="Warning" />
          <div>
            Current selection is not valid.
            <br />
            Select either an instructor or an aircraft type to see resuls.
          </div>
        </div>
      )}
      <AnimatePresence exitBeforeEnter>
        {shouldShowResults && !resultsReady && <Spinner key="spinner" />}

        {shouldShowResults && resultsReady && (
          <motion.div
            key="results"
            className={cx('results')}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {slots.length === 0 && (
              <div className={cx('error-message')}>
                <img src={NoSlotsIcon} alt="No slots available" />
                <div>
                  There are no slots available for the chosen filters. Try changing selected instructor or aircraft.
                </div>
              </div>
            )}

            {slots.length > 0 && (
              <h3>
                Next {aircraftChoice === 'none' ? 'ground lesson' : 'available'} slots{' '}
                {instructor && instructor !== 'none' && (
                  <>
                    with <strong>{selectedInstructorLabel}</strong>
                  </>
                )}{' '}
                {aircraftChoice && aircraftChoice !== 'none' && (
                  <>
                    in <strong>{selectedAircraftLabel}</strong>
                  </>
                )}
              </h3>
            )}

            <ul className={cx('slot-list')}>
              <Trail
                items={slots.slice(0, (slotsPage + 1) * 10)}
                keys={(slot: Slot) => slot.id}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
              >
                {(slot) => (props) => (
                  <animated.li style={props}>
                    <button className={cx('slot')}>
                      <div className={cx('thumbnails')}>
                        <div className={cx('thumbnail-wrapper')}>
                          {slot.aircraft && (
                            <div
                              className={cx('thumbnail')}
                              style={{ backgroundImage: `url(${slot.aircraft?.thumbnailUrl})` }}
                            />
                          )}
                          {slot.instructor && (
                            <div
                              className={cx('thumbnail')}
                              style={{ backgroundImage: `url(${slot.instructor.avatarUrl || DefaultAvatar})` }}
                            />
                          )}
                        </div>

                        <div className={cx('main-details')}>
                          <div>
                            {slot.aircraft
                              ? `${slot.aircraft.make} ${slot.aircraft.model} (${slot.aircraft.registration})`
                              : 'Ground lesson'}
                          </div>
                          <div className={cx('instructor-name')}>
                            {slot.instructor
                              ? `${slot.instructor.firstName} ${slot.instructor.lastName}`
                              : 'Aircraft only'}
                          </div>
                        </div>
                      </div>

                      <div className={cx('date-and-time')}>
                        <div className={cx('date-row')}>
                          <CalendarIcon className={cx('icon')} /> {slot.startTime.format('ddd, DD.MM.YYYY')}
                        </div>
                        <div className={cx('date-row', 'time-row')}>
                          {/* <ClockIcon className={cx('icon')} /> */}
                          {slot.startTime.format('ha')} - {slot.endTime.format('ha')}
                        </div>
                      </div>

                      <div className={cx('cta')}>
                        <div>Book Now</div>
                        <div>
                          <small>
                            from £
                            {slot.instructor
                              ? !slot.aircraft
                                ? 36 * (duration / 60)
                                : `${slot.aircraft.hourlyRate}/h`
                              : `${slot.aircraft?.selfHireHourlyRate}/h`}
                          </small>
                        </div>
                      </div>
                    </button>
                  </animated.li>
                )}
              </Trail>
            </ul>

            {slots.length > (slotsPage + 1) * 10 && (
              <div className={cx('show-next-wrapper')}>
                <button className={cx('show-next-button')} onClick={() => setSlotsPage(slotsPage + 1)}>
                  Show Next
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default BookingForm;
