import classNames from 'classnames/bind';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { BookingForm } from '../../../components/BookingForm';
import { FullSchedule } from '../../../components/FullSchedule';
import { Switch } from '../../../components/Switch';
import styles from './Hero.module.scss';

const cx = classNames.bind(styles);

const Hero: React.FC = () => {
  const [activeModule, setActiveModule] = useState<'find-slot' | 'view-schedule'>('find-slot');

  return (
    <div className={cx('hero')}>
      {/* <button className={cx('school-name')}>Flight Training London</button> */}
      {/* <div className={cx('school-name')}>
        <Dropdown
          options={[{ key: 'ftl', label: 'Flight Training London', description: 'Student' }]}
          label="Flight Training London"
          onChange={() => {}}
        />
      </div> */}

      <h2>Book Your Flight</h2>

      <Switch
        labels={['Find a Slot', 'View Schedule']}
        active={activeModule === 'find-slot' ? 0 : 1}
        onChange={(index) => setActiveModule(index === 0 ? 'find-slot' : 'view-schedule')}
      />

      <AnimatePresence exitBeforeEnter>
        {activeModule === 'find-slot' && <BookingForm />}
        {activeModule === 'view-schedule' && <FullSchedule />}
      </AnimatePresence>
    </div>
  );
};

export default Hero;
