import classNames from 'classnames/bind';
import React from 'react';
import { instructors } from '../../../data';
import { InstructorChoice } from '../../../types/instructor-choice';
import { Autocomplete } from '../../Autocomplete';
import { AutocompleteOption } from '../../Autocomplete/Autocomplete';
import AircraftOnly from './AircraftOnly.png';
import AnyInstructor from './AnyInstructor.png';
import DefaultAvatar from './DefaultAvatar.png';
import styles from './InstructorAutocomplete.module.scss';

const cx = classNames.bind(styles);

interface InstructorAutocompleteProps {
  instructor?: InstructorChoice;
  border?: boolean;
  onSelect: (choice: InstructorChoice) => void;
}

const anyInstructorOption: AutocompleteOption = {
  id: 'any',
  label: 'Any instructor',
  thumbnailUrl: AnyInstructor,
};

const noInstructorOption: AutocompleteOption = {
  id: 'none',
  label: 'No instructor',
  description: 'Aircraft booking only',
  thumbnailUrl: AircraftOnly,
};

const options: AutocompleteOption[] = [
  anyInstructorOption,
  noInstructorOption,
  ...instructors.map((i) => ({
    id: i.id,
    label: `${i.firstName} ${i.lastName}`,
    thumbnailUrl: i.avatarUrl,
  })),
];

const filterFn = (filter: string, options: AutocompleteOption[]) => {
  return options.filter((i) => i.label.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
};

const InstructorAutocomplete: React.FC<InstructorAutocompleteProps> = ({ instructor, border = true, onSelect }) => (
  <div className={cx('wrapper', { border })}>
    <Autocomplete
      options={options}
      selected={options.find((o) => o.id === instructor)}
      defaultThumbnail={DefaultAvatar}
      filterFn={filterFn}
      placeholder="Select instructor"
      onSelect={({ id }) => onSelect(id)}
    />
  </div>
);

export default InstructorAutocomplete;
