import classNames from 'classnames/bind';
import React from 'react';
import styles from './Toggle.module.scss';

const cx = classNames.bind(styles);

interface ToggleProps {
  value: string;
  options: { [key: string]: string };
  onChange: (value: any) => void;
}

const Toggle: React.FC<ToggleProps> = ({ value, options, onChange }) => {
  const rightButtonActive = Object.keys(options)[1] === value;

  return (
    <div className={cx('toggle')}>
      <div className={cx('pill', { right: rightButtonActive })} />
      {Object.entries(options).map(([key, label]) => (
        <button key={key} className={cx({ active: key === value })} onClick={() => onChange(key)}>
          {label}
        </button>
      ))}
    </div>
  );
};

export default Toggle;
