import classNames from 'classnames/bind';
import React from 'react';
import { Avatar } from '../Avatar';
import styles from './ResourceButton.module.scss';

const cx = classNames.bind(styles);

interface ResourceButtonProps {
  title: string;
  description?: string;
  imageUrl?: string;
  type: 'aircraft' | 'instructor';
}

const ResourceButton: React.FC<ResourceButtonProps> = ({ title, description, imageUrl, type }) => {
  return (
    <button className={cx('resource-button')}>
      <Avatar imageUrl={imageUrl} type={type} />
      <div>
        <div>{title}</div>
        {description && <div className={cx('description')}>{description}</div>}
      </div>
    </button>
  );
};

export default ResourceButton;
