import classNames from 'classnames/bind';
import React from 'react';
import styles from './Avatar.module.scss';

const cx = classNames.bind(styles);

interface AvatarProps {
  imageUrl?: string;
  type: 'aircraft' | 'instructor';
}

const Avatar: React.FC<AvatarProps> = ({ imageUrl, type }) => {
  return <div className={cx('avatar', type)} style={{ backgroundImage: imageUrl && `url(${imageUrl})` }} />;
};

export default Avatar;
