import classNames from 'classnames/bind';
import React from 'react';
import { Hero } from './Hero';
import styles from './QuickBooking.module.scss';

const cx = classNames.bind(styles);

const QuickBooking: React.FC = () => {
  return (
    <div className={cx('quick-booking')}>
      <Hero />
    </div>
  );
};

export default QuickBooking;
