import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useKey } from 'react-use';
import styles from './Autocomplete.module.scss';

const cx = classNames.bind(styles);

export interface AutocompleteOption {
  id: string | number;
  label: string;
  description?: string;
  thumbnailUrl?: string;
  data?: any;
}

interface AutocompleteProps {
  options: AutocompleteOption[];
  selected?: AutocompleteOption;
  placeholder: string;
  defaultThumbnail: string;
  filterFn: (filter: string, options: AutocompleteOption[]) => AutocompleteOption[];
  onSelect: (value: any) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  selected,
  placeholder,
  defaultThumbnail,
  filterFn,
  onSelect,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  const filteredOptions = filterFn(filter, options);

  useKey('Escape', () => setOpen(false));

  const handleFocus = () => {
    setOpen(true);
  };

  const handleOutsideClick = () => {
    setOpen(false);
  };

  const handleSelect = (option: AutocompleteOption) => {
    onSelect(option);
    setOpen(false);
  };

  const handleChoiceClick = () => {
    setOpen(true);
    setFilter('');

    ref?.current?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSelect(filteredOptions[activeIndex]);
      setOpen(false);
    }

    if (e.key === 'ArrowDown') {
      setActiveIndex((index) => (index + 1 >= filteredOptions.length ? 0 : index + 1));
    }

    if (e.key === 'ArrowUp') {
      setActiveIndex((index) => (index - 1 < 0 ? filteredOptions.length - 1 : index - 1));
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <input
        ref={ref}
        className={cx('autocomplete')}
        type="text"
        placeholder={placeholder}
        onFocus={handleFocus}
        value={filter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      {selected && !open && (
        <div className={cx('selected-choice')} onClick={handleChoiceClick}>
          <div
            className={cx('avatar')}
            style={{ backgroundImage: `url(${selected.thumbnailUrl || defaultThumbnail})` }}
          />
          <div>
            {selected.label}
            {selected.description && (
              <>
                <br />
                <small>{selected.description}</small>
              </>
            )}
          </div>
        </div>
      )}

      <AnimatePresence>
        {open && (
          <motion.div
            className={`${cx('list-container')} autocomplete-dropdown`}
            animate="on"
            exit="off"
            initial="off"
            variants={{ on: { opacity: 1 }, off: { opacity: 0 } }}
            transition={{ type: 'tween' }}
          >
            <ul>
              {filteredOptions.map((option, index) => (
                <li key={option.id}>
                  <button onClick={() => handleSelect(option)} className={cx({ active: activeIndex === index })}>
                    <div
                      className={cx('avatar')}
                      style={{ backgroundImage: `url(${option.thumbnailUrl || defaultThumbnail})` }}
                    />{' '}
                    <div>
                      {option.label}{' '}
                      {option.description && (
                        <>
                          <br />
                          <small>{option.description}</small>
                        </>
                      )}
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </OutsideClickHandler>
  );
};

export default Autocomplete;
