import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { Content } from '../../components/Content';
import { Heading } from '../../components/Heading';
import { ResourceButton } from '../../components/ResourceButton';
import { SearchInput } from '../../components/SearchInput';
import { Toggle } from '../../components/Toggle';
import { aircraft, instructors } from '../../data';
import styles from './Bookings.module.scss';
import RemoveIcon from './RemoveIcon.png';

const cx = classNames.bind(styles);

const dateFilterOptions = {
  upcoming: 'Upcoming',
  past: 'Past Bookings',
};

const headings: { [key in keyof typeof dateFilterOptions]: string } = {
  upcoming: 'Upcoming Bookings',
  past: 'Past Bookings',
};

const Bookings: React.FC = () => {
  const [dateFilter, setDateFilter] = useState<keyof typeof dateFilterOptions>('upcoming');

  return (
    <Content>
      <Heading>
        {headings[dateFilter]}
        <div style={{ display: 'flex' }}>
          <SearchInput />
          <Toggle value={dateFilter} options={dateFilterOptions} onChange={setDateFilter} />
        </div>
      </Heading>

      <table className={cx('table')}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Aircraft</th>
            <th>Instructor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Sat, 13.06.2020
              <br />
              <span className={cx('muted')}>9am - 11am</span>
            </td>
            <td>
              <ResourceButton
                type="aircraft"
                title="Piper PA-28 Warrior"
                description="G-AZAZ"
                imageUrl={aircraft.find((a) => a.id === 6)?.thumbnailUrl}
              />
            </td>
            <td>
              <ResourceButton
                type="instructor"
                title="Elliot Potter"
                imageUrl={instructors.find((i) => i.id === 2)?.avatarUrl}
              />
            </td>
            <td className={cx('actions')}>
              <button className={cx('danger')}>
                <img src={RemoveIcon} alt="Delete Booking" />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              Sat, 27.06.2020
              <br />
              <span className={cx('muted')}>9am - 11am</span>
            </td>
            <td>
              <ResourceButton
                type="aircraft"
                title="Piper PA-28 Warrior"
                description="G-AZAZ"
                imageUrl={aircraft.find((a) => a.id === 5)?.thumbnailUrl}
              />
            </td>
            <td>
              <ResourceButton
                type="instructor"
                title="Elliot Potter"
                imageUrl={instructors.find((i) => i.id === 2)?.avatarUrl}
              />
            </td>
            <td className={cx('actions')}>
              <button className={cx('danger')}>
                <img src={RemoveIcon} alt="Delete Booking" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {/*
      <Heading>Past Bookings</Heading>
      <p>You don't have any past bookings.</p> */}
    </Content>
  );
};

export default Bookings;
