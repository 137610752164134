import classNames from "classnames/bind";
import { motion } from "framer-motion";
import React from "react";
import { useSize } from "react-use";
import styles from "./Switch.module.scss";

const cx = classNames.bind(styles);

interface SwitchProps {
  labels: string[];
  active: number;
  onChange: (index: number) => void;
}

const Switch: React.FC<SwitchProps> = ({ labels, active, onChange }) => {
  const [firstButton, { width: firstButtonWidth }] = useSize(() => (
    <button
      className={cx({ active: active === 0 })}
      onClick={() => onChange(0)}
    >
      {labels[0]}
    </button>
  ));
  const [secondButton, { width: secondButtonWidth }] = useSize(() => (
    <button
      className={cx({ active: active === 1 })}
      onClick={() => onChange(1)}
    >
      {labels[1]}
    </button>
  ));

  return (
    <div className={cx("switch")}>
      <motion.div
        className={cx("pill")}
        style={{
          width: active === 0 ? firstButtonWidth : secondButtonWidth,
        }}
        animate={{
          x: active === 0 ? 0 : firstButtonWidth + 1,
        }}
        transition={{ type: "tween" }}
      />
      {firstButton}
      {secondButton}
    </div>
  );
};

export default Switch;
