import classNames from 'classnames/bind';
import { enGB } from 'date-fns/locale';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactClickOutside from 'react-outside-click-handler';
import { Dropdown } from '../../Dropdown';
import styles from './BookingFormFooter.module.scss';
import { ReactComponent as ChevronLeftIcon } from './ChevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './ChevronRight.svg';

const cx = classNames.bind(styles);

interface BookingFormFooterProps {
  startDate: moment.Moment;
  duration: number;
  startDateChanged: (date: moment.Moment) => void;
  durationChanged: (duration: number) => void;
}

const BookingFormFooter: React.FC<BookingFormFooterProps> = ({
  startDate,
  duration,
  startDateChanged,
  durationChanged,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ type: 'tween' }}
      className={cx('form-footer')}
    >
      <div className={cx('date-row')}>
        <span className={cx('label')}>From</span>{' '}
        <div className={cx('date-buttons-wrapper')}>
          <button
            className={cx('change-date-button', 'prev-day')}
            disabled={startDate.isSameOrBefore(moment())}
            onClick={() => startDateChanged(startDate.clone().subtract(1, 'day'))}
          >
            <ChevronLeftIcon />
          </button>{' '}
          <div className={cx('date-picker-wrapper')}>
            <ReactClickOutside onOutsideClick={() => setDatePickerOpen(false)}>
              <button className={cx('open-calendar-button')} onClick={() => setDatePickerOpen(!datePickerOpen)}>
                {startDate.format('ddd, DD.MM.YYYY')}
              </button>
              {datePickerOpen && (
                <div className={cx('date-picker')}>
                  <DatePicker
                    locale={enGB}
                    minDate={new Date()}
                    selected={startDate.toDate()}
                    onChange={(date) => {
                      startDateChanged(moment(date?.toString()));
                      setDatePickerOpen(false);
                    }}
                    inline
                  />
                </div>
              )}
            </ReactClickOutside>
          </div>
          <button
            className={cx('change-date-button')}
            onClick={() => startDateChanged(startDate.clone().add(1, 'day'))}
          >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div className={cx('label-and-airport-wrapper')}>
        <div>
          <span className={cx('label')}>Duration</span>{' '}
          {/* <button className={cx('duration-button')}>{duration / 60}h</button> */}
          <Dropdown
            label={`${duration / 60} hours`}
            options={[
              { key: 60, label: '1 hour' },
              { key: 2 * 60, label: '2 hours' },
              { key: 3 * 60, label: '3 hours' },
              { key: 4 * 60, label: '4 hours' },
              { key: 5 * 60, label: '5 hours' },
              { key: 6 * 60, label: '6 hours' },
              { key: 7 * 60, label: '7 hours' },
              { key: 8 * 60, label: '8 hours' },
            ]}
            onChange={(option) => durationChanged(parseInt(option.key.toString(), 10))}
          />
        </div>

        {/* <button className={cx('duration-button')}>EGTR</button> */}
        <Dropdown
          label="EGTR"
          options={[{ key: 'EGTR', label: 'EGTR', description: 'Elstree Airfield' }]}
          align="right"
          onChange={() => {}}
        />
      </div>
    </motion.div>
  );
};

export default BookingFormFooter;
