import classNames from 'classnames/bind';
import React from 'react';
import styles from './Heading.module.scss';

const cx = classNames.bind(styles);

const Heading: React.FC = ({ children }) => {
  return <header className={cx('heading')}>{children}</header>;
};

export default Heading;
