import classNames from 'classnames/bind';
import React from 'react';
import { NavLink } from 'react-router-dom';
import AircraftIcon from './Aircraft.png';
import AvailabilityIcon from './Availability.png';
import CalendarPlusIcon from './CalendarPlus.png';
import Logo from './Logo.png';
import styles from './Nav.module.scss';
import PeopleIcon from './People.png';
import ScheduleIcon from './Schedule.png';
import SettingsIcon from './Settings.svg';
import UserIcon from './User.png';

const cx = classNames.bind(styles);

interface NavProps {
  light?: boolean;
}

const Nav: React.FC<NavProps> = ({ light }) => {
  return (
    <header className={cx('nav', { light })}>
      <img className={cx('logo')} src={Logo} alt="SkyBound" />

      <ul>
        <li>
          <NavLink to="/" activeClassName={cx('active')} exact>
            <img src={CalendarPlusIcon} alt="Book Now" />
            {/* <span className={cx('tooltip')}>Book Now</span> */}
          </NavLink>
        </li>
        <li>
          <NavLink to="/bookings" activeClassName={cx('active')}>
            <img src={ScheduleIcon} alt="My Bookings" />
            {/* <span className={cx('tooltip')}>My Bookings</span> */}
          </NavLink>
        </li>
        <li>
          <NavLink to="/instructors" activeClassName={cx('active')}>
            <img src={PeopleIcon} alt="Instructors" />
            {/* <span className={cx('tooltip')}>Instructors</span> */}
          </NavLink>
        </li>
        <li>
          <NavLink to="/aircraft" activeClassName={cx('active')}>
            <img src={AircraftIcon} alt="Aircraft" />
            {/* <span className={cx('tooltip')}>Aircraft</span> */}
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/availability" activeClassName={cx('active')}>
            <img src={AvailabilityIcon} alt="Availability" />
            <span className={cx('tooltip')}>Availability</span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/settings" activeClassName={cx('active')}>
            <img src={SettingsIcon} alt="Settings" />
            <span className={cx('tooltip')}>Settings</span>
          </NavLink>
        </li> */}
      </ul>

      <ul className={cx('nav-bottom')}>
        <li>
          <NavLink to="/settings" activeClassName={cx('active')}>
            <img src={UserIcon} alt="My Profile" />
            {/* <span className={cx('tooltip')}>My Profile</span> */}
          </NavLink>
        </li>
      </ul>
    </header>
  );
};

export default Nav;
