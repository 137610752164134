import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  on: { opacity: 1 },
  off: { opacity: 0 },
};

const transition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.3,
};

const AnimatedRoute: React.FC = ({ children }) => {
  return (
    <motion.div variants={variants} transition={transition} initial="off" animate="on" exit="off">
      {children}
    </motion.div>
  );
};

export default AnimatedRoute;
