import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import React from 'react';
import styles from './Spinner.module.scss';
import { ReactComponent as SpinnerIcon } from './Spinner.svg';

const cx = classNames.bind(styles);

const Spinner: React.FC = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={cx('spinner')}>
      <SpinnerIcon />
    </motion.div>
  );
};

export default Spinner;
