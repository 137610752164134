import config from '../config';
import { DataResponse } from '../interfaces/data-response';
import { User } from '../models/user';

const headers = {
  'Content-type': 'application/json',
  Accept: 'application/json',
};

const tokenStorageKey = 'auth.token';
const emailStorageKey = 'auth.email';

interface TokenResponse {
  access_token: string;
}

const AuthClient = {
  getToken: () => localStorage.getItem(tokenStorageKey),

  saveToken: (token: string) => localStorage.setItem(tokenStorageKey, token),

  deleteToken: () => localStorage.removeItem(tokenStorageKey),

  getEmail: (): string => localStorage.getItem(emailStorageKey) || '',

  saveEmail: (email: string) => localStorage.setItem(emailStorageKey, email),

  login: async (email: string, password: string): Promise<User> => {
    const body = { username: email, password };

    const res = await fetch(`${config.apiUrl}/auth/token`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      return new Promise((resolve) => resolve(undefined));
    }

    const { access_token }: TokenResponse = await res.json();

    AuthClient.saveToken(access_token);
    AuthClient.saveEmail(email);

    return await AuthClient.getUser();
  },

  logout: async () => {
    const token = AuthClient.getToken();

    if (!token) {
      return new Promise((resolve) => resolve());
    }

    AuthClient.deleteToken();

    await fetch(`${config.apiUrl}/auth/logout`, {
      headers: { ...headers, authorization: `Bearer ${token}` },
    });
  },

  getUser: async (): Promise<User> => {
    const token = AuthClient.getToken();

    if (!token) {
      return new Promise((resolve) => resolve(undefined));
    }

    const res = await fetch(`${config.apiUrl}/auth/user`, {
      headers: { ...headers, authorization: `Bearer ${token}` },
    });
    const { data: user }: DataResponse<User> = await res.json();

    return user;
  },
};

export default AuthClient;
