import React, { createContext, useContext } from 'react';
import { User } from '../models/user';
import { useAuth } from './auth-context';

const UserContext = createContext<User | undefined>(undefined);

const UserProvider: React.FC = (props) => (
  <UserContext.Provider value={useAuth().user} {...props}></UserContext.Provider>
);

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
