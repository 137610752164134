import classNames from 'classnames/bind';
import { enGB } from 'date-fns/locale';
import { motion, Transition } from 'framer-motion';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactClickOutside from 'react-outside-click-handler';
import { aircraft, instructors } from '../../data';
import { BookingService } from '../../services/booking';
import { AircraftChoice } from '../../types/aircraft-choice';
import { InstructorChoice } from '../../types/instructor-choice';
import { Avatar } from '../Avatar';
import { AircraftAutocomplete } from '../BookingForm/AircraftAutocomplete';
import { InstructorAutocomplete } from '../BookingForm/InstructorAutocomplete';
import { Dropdown } from '../Dropdown';
import { ReactComponent as ChevronLeftIcon } from './ChevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './ChevronRight.svg';
import styles from './FullSchedule.module.scss';

const cx = classNames.bind(styles);

const variants = {
  in: { y: 0, opacity: 1 },
  out: { y: 5, opacity: 0 },
};

const transition: Transition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.5,
};

const FullSchedule: React.FC = () => {
  const [date, setDate] = useState<moment.Moment>(moment());
  const [duration, setDuration] = useState(120);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [aircraftChoice, setAircraft] = useState<AircraftChoice>();
  const [instructorChoice, setInstructor] = useState<InstructorChoice>();
  const [hoverDate, setHoverDate] = useState<moment.Moment>();
  const [hoverEndDate, setHoverEndDate] = useState<moment.Moment>();
  const [hoverRow, setHoverRow] = useState<string>();

  const cellTimes: moment.Moment[] = [];
  for (
    let d = moment(date).hour(9).minute(0).second(0);
    d.diff(moment(d).hour(18).minute(30)) <= 0;
    d.add(30, 'minutes')
  ) {
    cellTimes.push(d.clone());
  }

  useEffect(() => {
    setHoverEndDate(hoverDate?.clone().add(duration, 'minutes'));
  }, [hoverDate, duration]);

  return (
    <motion.div
      variants={variants}
      transition={transition}
      initial="out"
      animate="in"
      exit="out"
      className={cx('full-schedule')}
    >
      <div className={cx('heading')}>
        <div className={cx('controls')}>
          <div className={cx('autocomplete-wrapper')}>
            <InstructorAutocomplete instructor={instructorChoice} border={false} onSelect={setInstructor} />
            <AircraftAutocomplete aircraft={aircraftChoice} onSelect={setAircraft} />
          </div>

          <div className={cx('date-buttons-wrapper')}>
            <button
              className={cx('change-date-button', 'prev-day')}
              disabled={date.isSameOrBefore(moment())}
              onClick={() => setDate(date.clone().subtract(1, 'day'))}
            >
              <ChevronLeftIcon />
            </button>{' '}
            <div className={cx('date-picker-wrapper')}>
              <ReactClickOutside onOutsideClick={() => setDatePickerOpen(false)}>
                <button className={cx('open-calendar-button')} onClick={() => setDatePickerOpen(!datePickerOpen)}>
                  {date.format('ddd, DD.MM.YYYY')}
                </button>
                {datePickerOpen && (
                  <div className={cx('date-picker')}>
                    <DatePicker
                      locale={enGB}
                      minDate={new Date()}
                      selected={date.toDate()}
                      onChange={(date) => {
                        setDate(moment(date?.toString()));
                        setDatePickerOpen(false);
                      }}
                      inline
                    />
                  </div>
                )}
              </ReactClickOutside>
            </div>
            <button className={cx('change-date-button')} onClick={() => setDate(date.clone().add(1, 'day'))}>
              <ChevronRightIcon />
            </button>
          </div>

          <div className={cx('duration-location-wrapper')}>
            <div>
              <span className={cx('label')}>Duration</span>{' '}
              {/* <button className={cx('duration-button')}>{duration / 60}h</button> */}
              <Dropdown
                label={`${duration / 60} hours`}
                options={[
                  { key: 60, label: '1 hour' },
                  { key: 2 * 60, label: '2 hours' },
                  { key: 3 * 60, label: '3 hours' },
                  { key: 4 * 60, label: '4 hours' },
                  { key: 5 * 60, label: '5 hours' },
                  { key: 6 * 60, label: '6 hours' },
                  { key: 7 * 60, label: '7 hours' },
                  { key: 8 * 60, label: '8 hours' },
                ]}
                onChange={(option) => setDuration(parseInt(option.key.toString(), 10))}
              />
            </div>

            <Dropdown
              label="EGTR"
              options={[{ key: 'EGTR', label: 'EGTR', description: 'Elstree Airfield' }]}
              align="right"
              onChange={() => {}}
            />
          </div>
        </div>

        <div className={cx('hours')}>
          {cellTimes.map((time) => (
            <div key={time.unix()} className={cx('cell')}>
              {time.minutes() === 0 && <span>{time.format('ha')}</span>}
            </div>
          ))}
        </div>
      </div>

      <div
        className={cx('calendar')}
        onMouseOut={() => {
          setHoverDate(undefined);
          setHoverRow(undefined);
        }}
      >
        {aircraft.filter(BookingService.filterAircraft(aircraftChoice)).map((resource) => (
          <div key={resource.id} className={cx('resource')}>
            <div className={cx('resource-name')}>
              <Avatar imageUrl={resource.thumbnailUrl} type="aircraft" />
              <div>
                <div>
                  {resource.make} {resource.model}
                </div>
                <div>
                  <small>{resource.registration}</small>
                </div>
              </div>
            </div>
            {cellTimes.map((time) => {
              return (
                <div
                  key={`aircraft-${resource.id}-${time.unix()}`}
                  className={cx('cell', {
                    hover:
                      hoverRow === `aircraft-${resource.id}` && time.isBetween(hoverDate, hoverEndDate, 'minute', '[)'),
                  })}
                  onMouseOver={() => {
                    setHoverDate(time);
                    setHoverRow(`aircraft-${resource.id}`);
                  }}
                  // onMouseOut={() => setHoverDate(undefined)}
                >
                  {time.minutes() === 0 && <span>{time.format('ha')}</span>}
                </div>
              );
            })}
          </div>
        ))}

        {instructors.filter(BookingService.filterInstructor(instructorChoice)).map((resource, index) => (
          <div
            key={resource.id}
            className={cx('resource', {
              last: index === instructors.filter(BookingService.filterInstructor(instructorChoice)).length - 1,
            })}
          >
            <div className={cx('resource-name')}>
              <Avatar imageUrl={resource.avatarUrl} type="instructor" />
              <div>
                {resource.firstName} {resource.lastName}
              </div>
            </div>
            {cellTimes.map((time) => (
              <div
                key={`instructor-${resource.id}-${time.unix()}`}
                className={cx('cell', {
                  hover:
                    hoverRow === `instructor-${resource.id}` && time.isBetween(hoverDate, hoverEndDate, 'minute', '[)'),
                })}
                onMouseOver={() => {
                  setHoverDate(time);
                  setHoverRow(`instructor-${resource.id}`);
                }}
                // onMouseOut={() => setHoverDate(undefined)}
              >
                {time.minutes() === 0 && <span>{time.format('ha')}</span>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default FullSchedule;
