import React from 'react';
import { Content } from '../../components/Content';
import { Heading } from '../../components/Heading';

const Availability: React.FC = () => {
  return (
    <Content>
      <Heading>Availability</Heading>
    </Content>
  );
};

export default Availability;
