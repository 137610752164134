import moment from 'moment';
import { Aircraft } from './interfaces/aircraft';
import { Instructor } from './interfaces/instructor';
import { InstructorAvailability } from './interfaces/instructor-availability';

export const instructors: Instructor[] = [
  { id: 1, firstName: 'Aaron', lastName: 'Willis', avatarUrl: '/images/instructors/XzA5Njc1OTUuanBn.jpg' },
  { id: 2, firstName: 'Elliot', lastName: 'Potter', avatarUrl: '/images/instructors/XzA0MTg3NjguanBn.jpg' },
  { id: 3, firstName: 'George', lastName: 'Patel', avatarUrl: '/images/instructors/XzAzNDI4ODUuanBn.jpg' },
  { id: 4, firstName: 'Jeffrey', lastName: 'Smith', avatarUrl: '/images/instructors/XzAzOTc1MzcuanBn.jpg' },
  { id: 5, firstName: 'Jack', lastName: 'Stephenson', avatarUrl: '/images/instructors/XzAzNTIxMzcuanBn.jpg' },
  { id: 6, firstName: 'Joseph', lastName: 'Bartlett', avatarUrl: '/images/instructors/XzAwOTI1ODEuanBn.jpg' },
  { id: 7, firstName: 'Natasha', lastName: 'Dodd', avatarUrl: '/images/instructors/XzA2MTY2NzQuanBn.jpg' },
  { id: 12, firstName: 'Sami', lastName: 'Jones' },
  { id: 8, firstName: 'Pedro', lastName: 'Martin', avatarUrl: '/images/instructors/XzAwNDMzODIuanBn.jpg' },
  { id: 9, firstName: 'Farid', lastName: 'Shaikh', avatarUrl: '/images/instructors/XzAwMjA2NjAuanBn.jpg' },
  { id: 10, firstName: 'Andy', lastName: 'Barnes', avatarUrl: '/images/instructors/XzAxNjgzMTEuanBn.jpg' },
  { id: 11, firstName: 'Harriet', lastName: 'Griffin', avatarUrl: '/images/instructors/XzAzMDkyNDYuanBn.jpg' },
  { id: 13, firstName: 'Adam', lastName: 'Hunter' },
];

export const aircraft: Aircraft[] = [
  {
    id: 1,
    make: 'Cessna',
    model: '152',
    registration: 'G-ABCD',
    hourlyRate: 199,
    selfHireHourlyRate: 140,
    thumbnailUrl: '/images/aircraft/gbflu.jpg',
  },
  {
    id: 2,
    make: 'Cessna',
    model: '152',
    registration: 'G-DEFG',
    hourlyRate: 199,
    selfHireHourlyRate: 140,
    thumbnailUrl: '/images/aircraft/gcipy.jpg',
  },
  {
    id: 3,
    make: 'Cessna',
    model: '152',
    registration: 'G-ZYXW',
    hourlyRate: 199,
    selfHireHourlyRate: 140,
    thumbnailUrl: '/images/aircraft/gbpme.jpg',
  },
  {
    id: 4,
    make: 'Cessna',
    model: '152',
    registration: 'G-AAAC',
    hourlyRate: 199,
    selfHireHourlyRate: 140,
    thumbnailUrl: '/images/aircraft/gclap.jpg',
  },
  {
    id: 5,
    make: 'Piper',
    model: 'PA-28 Warrior',
    registration: 'G-ABAB',
    hourlyRate: 218,
    selfHireHourlyRate: 160,
    thumbnailUrl: '/images/aircraft/gcczv.jpg',
  },
  {
    id: 6,
    make: 'Piper',
    model: 'PA-28 Warrior',
    registration: 'G-AZAZ',
    hourlyRate: 218,
    selfHireHourlyRate: 160,
    thumbnailUrl: '/images/aircraft/gwars.jpg',
  },
  {
    id: 7,
    make: 'Piper',
    model: 'PA-28 Warrior',
    registration: 'G-ZZAA',
    hourlyRate: 218,
    selfHireHourlyRate: 160,
    thumbnailUrl: '/images/aircraft/golea.jpg',
  },
  {
    id: 8,
    make: 'Piper',
    model: 'PA-28 Archer',
    registration: 'G-NNAA',
    hourlyRate: 224,
    selfHireHourlyRate: 170,
    thumbnailUrl: '/images/aircraft/gkiki.jpg',
  },
  {
    id: 9,
    make: 'Piper',
    model: 'PA-28 Archer',
    registration: 'G-LOVE',
    hourlyRate: 224,
    selfHireHourlyRate: 170,
    thumbnailUrl: '/images/aircraft/glvrs.jpg',
  },
  {
    id: 9,
    make: 'Cirrus',
    model: 'SR-20 G3',
    registration: 'N1234AB',
    hourlyRate: 550,
    selfHireHourlyRate: 490,
  },
];

export const availability: InstructorAvailability[] = Array.from({ length: 11 }).flatMap((v, i) => [
  {
    instructorId: i + 1,
    startTime: moment().add(1, 'day').hour(9),
    endTime: moment().add(1, 'day').hour(19),
  },
  {
    instructorId: i + 1,
    startTime: moment().add(2, 'day').hour(9),
    endTime: moment().add(2, 'day').hour(19),
  },
  {
    instructorId: i + 1,
    startTime: moment().add(3, 'day').hour(9),
    endTime: moment().add(3, 'day').hour(15),
  },
]);
