import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../context/auth-context';
import { UserProvider } from '../context/user-context';

const AppProviders: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppProviders;
