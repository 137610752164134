import React from 'react';
import { useAuth } from '../../context/auth-context';
import { AuthenticatedApp } from './AuthenticatedApp';
import { UnauthenticatedApp } from './UnauthenticatedApp';

const App: React.FC = () => {
  const { user } = useAuth();

  return <AuthenticatedApp />;

  // return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;
