import moment from 'moment';
import { aircraft, availability, instructors } from '../data';
import { Aircraft } from '../interfaces/aircraft';
import { Instructor } from '../interfaces/instructor';
import { Slot } from '../interfaces/slot';
import { AircraftChoice } from '../types/aircraft-choice';
import { InstructorChoice } from '../types/instructor-choice';

export const BookingService = {
  filterAircraft(choice?: AircraftChoice) {
    return (aircraft: Aircraft): boolean => {
      // filter by aircraft id / type
      if (!choice || choice === 'any') {
        return true;
      }

      if (typeof choice === 'number') {
        return aircraft.id === choice;
      }

      return choice === aircraft.make || choice === `${aircraft.make} ${aircraft.model}`;
    };
  },

  filterInstructor(instructorChoice?: InstructorChoice) {
    return (instructor: Instructor): boolean => {
      if (!instructorChoice || instructorChoice === 'any') {
        return true;
      }

      if (instructorChoice === 'none') {
        return false;
      }

      return instructor.id === instructorChoice;
    };
  },

  findSlots(
    instructorChoice?: InstructorChoice,
    aircraftChoice?: AircraftChoice,
    startDate?: moment.Moment,
    duration?: number,
  ): Slot[] {
    if (!startDate || !duration) {
      return [];
    }

    const isAircraftSelected = aircraftChoice !== 'none';

    // No instructor and no aircraft selection is invalid
    if ((!instructorChoice || instructorChoice === 'none') && !isAircraftSelected) {
      return [];
    }

    // Aircraft booking only
    if (!instructorChoice || instructorChoice === 'none') {
      const slots: Slot[] = [];

      for (
        let sDay = moment(startDate).startOf('day');
        sDay.isBefore(moment().add(31, 'days').startOf('day'));
        sDay.add(1, 'day')
      ) {
        for (
          let sTime = sDay.isSame(moment().startOf('day'))
            ? moment().clone().add(1, 'hour').startOf('hour')
            : sDay.clone().hour(9);
          sTime.diff(sDay.clone().hour(19).subtract(duration, 'minutes'));
          sTime.add(1, 'hour')
        ) {
          slots.push(
            ...aircraft.filter(this.filterAircraft(aircraftChoice)).map(
              (aircraft) =>
                ({
                  id: `${aircraft.id}-${sTime.unix()}-${moment(sTime).add(duration, 'minutes').unix()}`,
                  aircraft,
                  startTime: moment(sTime),
                  endTime: moment(sTime).add(duration, 'minutes'),
                  price: aircraft.selfHireHourlyRate,
                  priceType: 'hourly',
                } as Slot),
            ),
          );
        }
      }

      return slots;
    }

    // Find all possible slots for the selected instructors
    const filteredAvailability =
      instructorChoice === 'any' ? availability : availability.filter((a) => a.instructorId === instructorChoice);

    return filteredAvailability
      .filter((a) => a !== undefined)
      .flatMap((a) => {
        if (!a || a.startTime < startDate?.startOf('day')) {
          return [];
        }

        const availabilitySlots: Slot[] = [];

        for (
          let start = moment(a.startTime);
          start.diff(a.endTime.clone().subtract(duration, 'minutes')) <= 0;
          start.add(2, 'hours')
        ) {
          const slot: Slot = {
            id: `${instructors.find((i) => i.id === a.instructorId)?.id}-${start.unix()}-${moment(start)
              .add(duration, 'minutes')
              .unix()}`,
            instructor: instructors.find((i) => i.id === a.instructorId),
            startTime: start.clone(),
            endTime: start.clone().add(duration, 'minutes'),
            price: isAircraftSelected ? 0 : 36 * (duration / 60),
            priceType: isAircraftSelected ? 'hourly' : 'total',
          };

          if (isAircraftSelected) {
            const aircraftInstructorSlots = aircraft
              .filter(this.filterAircraft(aircraftChoice))
              .filter(() => {
                // Check if the aircraft is available during the booking
                return true;
              })
              .map((aircraft) => ({
                ...slot,
                id: `${aircraft.id}-${instructors.find((i) => i.id === a.instructorId)?.id}-${start.unix()}-${moment(
                  start,
                )
                  .add(duration, 'minutes')
                  .unix()}`,
                aircraft,
                price: aircraft.selfHireHourlyRate,
              }));

            availabilitySlots.push(...aircraftInstructorSlots);
          } else {
            availabilitySlots.push(slot);
          }
        }

        return availabilitySlots;
      })
      .sort((a: Slot, b: Slot) => {
        if (a.startTime.isSame(b.startTime)) {
          return 0;
        }

        return a.startTime.isAfter(b.startTime) ? 1 : -1;
      });
  },
};
