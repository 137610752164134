import classNames from 'classnames/bind';
import React from 'react';
import { Content } from '../../components/Content';
import { Heading } from '../../components/Heading';
import { SearchInput } from '../../components/SearchInput';
import styles from './Aircraft.module.scss';

const cx = classNames.bind(styles);

const Aircraft: React.FC = () => {
  return (
    <Content>
      <Heading>
        Aircraft
        <SearchInput />
      </Heading>
    </Content>
  );
};

export default Aircraft;
