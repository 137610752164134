import classNames from 'classnames/bind';
import _ from 'lodash';
import React from 'react';
import { aircraft } from '../../../data';
import { AircraftChoice } from '../../../types/aircraft-choice';
import Autocomplete, { AutocompleteOption } from '../../Autocomplete/Autocomplete';
import styles from './AircraftAutocomplete.module.scss';
import AnyAircraft from './AnyAircraft.png';
import CessnaLogo from './Cessna.png';
import CirrusLogo from './Cirrus.png';
import DefaultAvatar from './DefaultAvatar.png';
import GroundSchool from './GroundSchool.png';
import PiperLogo from './Piper.png';

const cx = classNames.bind(styles);

const thumbnails: { [key: string]: string } = {
  Cessna: CessnaLogo,
  Piper: PiperLogo,
  Cirrus: CirrusLogo,
};

interface AircraftAutocompleteProps {
  aircraft?: AircraftChoice;
  onSelect: (choice: AircraftChoice) => void;
}

const anyAircraftOption: AutocompleteOption = {
  id: 'any',
  label: 'Any aircraft',
  thumbnailUrl: AnyAircraft,
};

const noAircraftOption: AutocompleteOption = {
  id: 'none',
  label: 'No aircraft',
  description: 'Ground school booking',
  thumbnailUrl: GroundSchool,
};

const aircraftMakeOptions: AutocompleteOption[] = _.uniqBy(aircraft, 'make')
  .map((a) => ({
    id: a.make,
    label: `Any ${a.make}`,
    description: `${aircraft.filter((c) => a.make === c.make).length} available`,
    thumbnailUrl: Object.keys(thumbnails).includes(a.make) ? thumbnails[a.make] : undefined,
    data: {
      count: aircraft.filter((c) => a.make === c.make).length,
    },
  }))
  .filter((a) => a.data.count > 1);

const aircraftModelOptions: AutocompleteOption[] = _.uniqBy(aircraft, (a) => `${a.make} ${a.model}`)
  .map((a) => ({
    id: `${a.make} ${a.model}`,
    label: `Any ${a.make} ${a.model}`,
    description: `${aircraft.filter((c) => `${c.make} ${c.model}` === `${a.make} ${a.model}`).length} available`,
    thumbnailUrl: Object.keys(thumbnails).includes(a.make) ? thumbnails[a.make] : undefined,
    data: {
      count: aircraft.filter((c) => `${c.make} ${c.model}` === `${a.make} ${a.model}`).length,
    },
  }))
  .filter((a) => a.data.count > 1);

const options: AutocompleteOption[] = [
  anyAircraftOption,
  noAircraftOption,
  ...aircraft.map((i) => ({
    id: i.id,
    label: `${i.make} ${i.model}`,
    description: i.registration,
    thumbnailUrl: i.thumbnailUrl,
    data: i,
  })),
  ...aircraftMakeOptions,
  ...aircraftModelOptions,
];

const filterFn = (filter: string, options: AutocompleteOption[]) => {
  return options.filter(
    (i) =>
      `${i.label} ${i.data?.registration} ${i.data?.registration?.replace('-', '')}`
        .toLocaleLowerCase()
        .indexOf(filter.toLocaleLowerCase()) !== -1,
  );
};

const AircraftAutocomplete: React.FC<AircraftAutocompleteProps> = ({ aircraft, onSelect }) => (
  <div className={cx('wrapper')}>
    <Autocomplete
      options={options}
      selected={options.find((o) => o.id === aircraft)}
      defaultThumbnail={DefaultAvatar}
      filterFn={filterFn}
      placeholder="Select aircraft"
      onSelect={({ id }) => onSelect(id)}
    />
  </div>
);

export default AircraftAutocomplete;
