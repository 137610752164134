import React, { createContext, useContext, useEffect, useState } from 'react';
import { Spinner } from '../components/Spinner';
import { User } from '../models/user';
import AuthService from '../services/auth';

interface AuthContextValue {
  user?: User;
  token: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<User>();
  const [pending, setPending] = useState(true);

  const login = async (email: string, password: string): Promise<boolean> => {
    const user = await AuthService.login(email, password);

    if (!user) {
      return false;
    }

    setUser(user);

    return true;
  };

  const logout = () => {
    AuthService.logout();
    setUser(undefined);
  };

  useEffect(() => {
    AuthService.getUser()
      .then((user) => {
        if (!user) {
          AuthService.deleteToken();
        }

        setUser(user);
      })
      .catch(() => {
        AuthService.deleteToken();

        setUser(undefined);
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  if (pending) {
    return <Spinner />;
  }

  return <AuthContext.Provider value={{ user, token: AuthService.getToken(), login, logout }} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
