import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './Dropdown.module.scss';

const cx = classNames.bind(styles);

export interface DropdownOption {
  key: string | number;
  label: string;
  description?: string;
}

interface DropdownProps {
  label: string;
  options: DropdownOption[];
  align?: 'left' | 'right';
  onChange: (option: DropdownOption) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, align = 'left', onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cx('dropdown', align)}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <button className={cx('dropdown-button')} onClick={() => setOpen(!open)}>
          {label}
        </button>

        <AnimatePresence>
          {open && (
            <motion.div
              className={cx('dropdown-overlay')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ul>
                {options.map((option) => (
                  <li key={option.key}>
                    <button
                      onClick={() => {
                        onChange(option);
                        setOpen(false);
                      }}
                    >
                      <div>{option.label}</div>
                      {option.description && (
                        <div>
                          <small>{option.description}</small>
                        </div>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
