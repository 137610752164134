import classNames from 'classnames/bind';
import React from 'react';
import styles from './Content.module.scss';

const cx = classNames.bind(styles);

const Content: React.FC = ({ children }) => {
  return (
    <div className={cx('content')}>
      <div className={cx('content-inner')}>{children}</div>
    </div>
  );
};

export default Content;
