import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Aircraft } from '../../../views/Aircraft';
import { Availability } from '../../../views/Availability';
import { Bookings } from '../../../views/Bookings';
import { Instructors } from '../../../views/Instructors';
import { QuickBooking } from '../../../views/QuickBooking';
import { Settings } from '../../../views/Settings';
import { AnimatedRoute } from '../../atoms/AnimatedRoute';
import { Nav } from '../../Nav';

const AuthenticatedApp: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <AnimatedRoute>
              <QuickBooking />
            </AnimatedRoute>
          </Route>
          <Route path="/bookings">
            <AnimatedRoute>
              <Bookings />
            </AnimatedRoute>
          </Route>
          <Route path="/instructors">
            <AnimatedRoute>
              <Instructors />
            </AnimatedRoute>
          </Route>
          <Route path="/aircraft">
            <AnimatedRoute>
              <Aircraft />
            </AnimatedRoute>
          </Route>
          <Route path="/availability">
            <AnimatedRoute>
              <Availability />
            </AnimatedRoute>
          </Route>
          <Route path="/settings">
            <AnimatedRoute>
              <Settings />
            </AnimatedRoute>
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
};

export default AuthenticatedApp;
